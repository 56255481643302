import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import { ViewContainer, Box, Row, Col, Card, Typography, Button, Alert } from 'components/AORedesign';
import agentContext from 'contexts/agent.context';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import { ROUTE_REDESIGN_HOME_PAYMENT_BALANCE, ROUTE_REDESIGN_HOME_PAYMENT_HISTORY, ROUTE_REDESIGN_HOME_MANAGE_CURRENT_PAYMENT } from 'constants/routes';
import { CommercialLinesLinks, PersonalLinesLinks, PolicySearch, Tools } from 'components/AORedesignViews/Home';

const useStyles = makeStyles(() => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
}));

const Home = () => {
  const classes = useStyles();
  const { Name } = useContext(agentContext);
  const history = useHistory();

  const handlePaymentBalanceOnClick = () => history.push(ROUTE_REDESIGN_HOME_PAYMENT_BALANCE);

  const handlePaymentHistoryOnClick = () => history.push(ROUTE_REDESIGN_HOME_PAYMENT_HISTORY);

  const handleManageCurrentPaymentsOnClick = () => history.push(ROUTE_REDESIGN_HOME_MANAGE_CURRENT_PAYMENT);

  const [errorVisible, setErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Feature allowed={featureFlags.ACCESS_USER_INTERNAL}>
      <ViewContainer title={`Welcome, ${Name?.First}`} hidePolicyViewChange hideStartQuote>
        {errorVisible && (
          <Box mb={2}>
            <Alert severity="error">{errorMessage}</Alert>
          </Box>
        )}
        <Box mb={2}>
          <Row spacing={2}>
            <Col xs>
              <PolicySearch />
            </Col>
            <Feature allowed={featureFlags.ACCESS_PAYMENT_BALANCE_REPORT}>
              <Col xs={6} md={4}>
                <Card className={classes.fullHeight}>
                  <Box mb={3}>
                    <Typography variant="h6">Payment Management</Typography>
                  </Box>
                  <Box>
                    <Button variant="outlined" color="secondary" onClick={handlePaymentBalanceOnClick}>
                      Payment Balance
                    </Button>
                  </Box>
                  <Feature allowed={featureFlags.ACCESS_PAYMENT_HISTORY_AND_CURRENT_PAYMENTS}>
                    <Box mt={1}>
                      <Button variant="outlined" color="secondary" onClick={handlePaymentHistoryOnClick}>
                        Payment History
                      </Button>
                    </Box>
                    <Box mt={1}>
                      <Button variant="outlined" color="secondary" onClick={handleManageCurrentPaymentsOnClick}>
                        Manage Current Payments
                      </Button>
                    </Box>
                  </Feature>
                </Card>
              </Col>
            </Feature>
          </Row>
        </Box>
        <Box>
          <Row spacing={2}>
            <Col xs={6} md={4}>
              <Card className={classes.column}>
                <Box mb={2}>
                  <CommercialLinesLinks setErrorVisible={setErrorVisible} setErrorMessage={setErrorMessage} />
                </Box>
              </Card>
            </Col>
            <Col xs={6} md={4}>
              <Card className={classes.column}>
                <Box mb={3}>
                  <PersonalLinesLinks setErrorVisible={setErrorVisible} setErrorMessage={setErrorMessage} />
                </Box>
              </Card>
            </Col>
            <Col xs={6} md={4}>
              <Card className={classes.column}>
                <Box mb={3}>
                  <Tools />
                </Box>
              </Card>
            </Col>
          </Row>
        </Box>
      </ViewContainer>
    </Feature>
  );
};

export default Home;
