// (2/2022) Brent: FYI. Everything running locally and deployed will now come from REACT_APP_API_URL.
//                 You can change this locally in ".env" and in aws using app-config.yaml.
//                 I will leave this commented code here until deploy up the tiers is successful.
//
// const API_BASE_URL =
//   process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
//     ? `${process.env.REACT_APP_DEV_SBX_API}`
//     : `https://${process.env.REACT_APP_API_URL}`;
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`;

//
// Generic Get and Post Endpoints
//
const GET_PROXY_URL = `${API_BASE_URL}/proxy`;

// ???
//const WINDOW_LOCATION_HOSTNAME = typeof window !== `undefined` ? window.location.hostname : null;
//const WINDOW_LOCATION_ORIGIN = typeof window !== `undefined` ? window.location.origin : null;
//const LOCAL_HOST = `http://localhost:3000`;

//
// Okta Related Endpoints
//
const GET_CONFIGURATION_OKTA = `${API_BASE_URL}/configuration/okta`;
const GET_OKTA_LOGIN = `${GET_CONFIGURATION_OKTA}/login`;
//
// Constants used in construction of other endpoints
//
const GET_COMMERCIAL_LINES = '/commercial-lines';
const GET_PAYMENTS_DUE_URL = '/payments-due';
const GET_PERSONAL_LINES = '/personal-lines';

//
// Utility Endpoints
//
const GET_ALL_AGENTS = `${API_BASE_URL}/agent/search`;
const GET_ALLOWED_AGENCY_CODES = `${API_BASE_URL}/agent/allowed-agent-codes`;
const GET_AGENCY_HAS_MSA_ACCESS = `${API_BASE_URL}/agent/has-msa-access`;
const GET_ANNOUNCEMENTS = `${API_BASE_URL}/announcements`;
const GET_ANNOUNCEMENTS_ITEM = `${GET_ANNOUNCEMENTS}/item`;
const GET_ANNOUNCEMENTS_ITEMS = `${GET_ANNOUNCEMENTS}/items`;
const GET_ANNOUNCEMENTS_MONTHS = `${GET_ANNOUNCEMENTS}/selection-months`;
const GET_ANNOUNCEMENTS_PRODUCTS = `${GET_ANNOUNCEMENTS}/selection-products`;
const GET_ANNOUNCEMENTS_STATES = `${GET_ANNOUNCEMENTS}/selection-states`;
const GET_ANNOUNCEMENTS_YEARS = `${GET_ANNOUNCEMENTS}/selection-years`;
const GET_DASHBOARD = `${API_BASE_URL}/dashboard`;
const GET_DASHBOARD_OPEN_CLAIMS = `${GET_DASHBOARD}/open-claims`;
const GET_DASHBOARD_RENEWALS = `${GET_DASHBOARD}/upcoming-renewals`;
const GET_DASHBOARD_OVERDUE_PAYMENTS = `${GET_DASHBOARD}/overdue-payments`;
const GET_DASHBOARD_UNSOLD_QUOTES = `${GET_DASHBOARD}/unsold-quotes-init`;
const GET_DASHBOARD_UNSOLD_QUOTES_STATUS = `${GET_DASHBOARD}/unsold-quotes-status`;
const GET_NEWS = `${API_BASE_URL}/news`;
const GET_NEWS_DASHBOARD_ANNOUNCEMENTS = `${GET_NEWS}/current`;
const NEWS_ARTICLES = '/article';
const ALL_STATES_FOR_NEWS = `${API_BASE_URL}/newsadm/articles-all-states`;
const ADD_TEXT_NEWS = `${API_BASE_URL}/newsadm/link-article`;
const ADD_DOC_NEWS = `${API_BASE_URL}/newsadm/document-article`;
const GET_UI_DEFINITION = `${API_BASE_URL}/uiDefinition`;
const ALL_NEWS_ARTICLES = `${API_BASE_URL}/newsadm/news-articles`;
const NEWS_ARTICLE_BY_ID = `${API_BASE_URL}/newsadm/news-article`;
const UPDATE_ARTICLE = `${API_BASE_URL}/newsadm/update-article`;
const DELETE_ARTICLE = `${API_BASE_URL}/newsadm/delete-article`;

//
// Generic Redirect Endpoints
//
const GET_REDIRECT_URL = `${API_BASE_URL}/redirectUrl`;

//
// Admin Endpoints
//
const ADMIN_CHANGE_HISTORY_SEARCH = `${API_BASE_URL}/adm/change-history/search`;
const ADMIN_MANAGE_RESOURCES = `${API_BASE_URL}/adm/manage-resources`;
const ADMIN_REGIONAL_CONTACTS = `${API_BASE_URL}/adm/regional-contacts`;

//
// Agency Admin Endpoints
//
const AGENCY_ADMIN_BASE_URL = `${API_BASE_URL}/agencyadm`;
const AGENCY_ADMIN_SEARCH = `${AGENCY_ADMIN_BASE_URL}/search`;
const AGENCY_ADMIN_GET_CURRENT_AGENCIES = `${AGENCY_ADMIN_BASE_URL}/current-agencies`;
const AGENCY_ADMIN_GET_AVAILABLE_AGENCIES = `${AGENCY_ADMIN_BASE_URL}/available-for-user`;
const AGENCY_ADMIN_GET_POTENTIAL_AGENCIES = `${AGENCY_ADMIN_BASE_URL}/potential-agencies`;
const AGENCY_ADMIN_GET_SELECTED_AGENCIES = `${AGENCY_ADMIN_BASE_URL}/selected-for-user`;
const AGENCY_ADMIN_ADD = `${AGENCY_ADMIN_BASE_URL}/add`;
const AGENCY_ADMIN_PUT_ADD_REMOVE = `${AGENCY_ADMIN_BASE_URL}/add-remove`;
const AGENCY_ADMIN_REMOVE = `${AGENCY_ADMIN_BASE_URL}/remove`;

//
// Appetite Endpoints
//
const GET_APPETITE = `${API_BASE_URL}/appetite`;

//
// Autocomplete / Typeahead endpoints
//
const GET_PL_AUTO_COMPLETION_AGENCY = `${API_BASE_URL}/typeahead${GET_PERSONAL_LINES}/agency/`;
const GET_PL_AUTO_COMPLETION_INSURED = `${API_BASE_URL}/typeahead${GET_PERSONAL_LINES}/insured/`;
const GET_PL_AUTO_COMPLETION_POLICY_ONE_SHIELD = `${API_BASE_URL}/typeahead${GET_PERSONAL_LINES}/policy-one-shield/`;

//
// Billing Endpoints
//
const GET_BILLING_DETAILS = `${API_BASE_URL}/billing/details`;
const GET_BILLING_EXACT_SEARCH = `${API_BASE_URL}/billing/exact-search`;
const GET_BILLING_SEARCH = `${API_BASE_URL}/billing/search`;
const GET_BILLING_SEARCH_PAGE_COUNT = `${API_BASE_URL}/billing/count-by`;
const POST_BILLING_PAYMENT = `${API_BASE_URL}/billing/payment`;
const GET_BILLING_PAYMENT = `${API_BASE_URL}/billing/payment`;
const GET_BILLING_PAYMENT_ADJUSTABLE_PAYMENTS = `${GET_BILLING_PAYMENT}/adjustable-payments`;
const GET_BILLING_PAYMENT_ACCOUNTS = `${GET_BILLING_PAYMENT}/payment-accounts`;
const POST_BILLING_PAYMENT_POST_PAYMENTS = `${POST_BILLING_PAYMENT}/post-payments`;
const GET_BILLING_PAYMENT_NSF_PAYMENTS = `${GET_BILLING_PAYMENT}/nsf-payments`;
const POST_BILLING_PAYMENT_ADJUST_PAYMENT = `${POST_BILLING_PAYMENT}/adjust-payment`;
const POST_BILLING_PAYMENT_REVERSE_PAYMENT = `${POST_BILLING_PAYMENT}/reverse-payment`;
const POST_BILLING_SEARCH_SUGGESTIONS = `${API_BASE_URL}/billing/auto-completion`;
const POLICY_NUMBER_SEARCH = `${API_BASE_URL}/policies`;
const GET_POLICY_NUMBER_SEARCH = `${POLICY_NUMBER_SEARCH}/policy-number`;

const GET_BILLING_SEARCH_V2 = `${API_BASE_URL}/billing/search-v2`;
//
// Billing Constaπts
//
const ACCOUNT_NUMBER_URL = '/account-number';
const INSURED_LAST_NAME_URL = '/insured-last-name';
const INSURED_LAST_NAME_COUNT_URL = '/insured-last-name-count';
const POLICY_NUMBER_URL = '/policy-number';

//
// BrandFolder
//
const BRANDFOLDER_BASE = `${API_BASE_URL}/brandfolder`;
const BRANDFOLDER_DOCS_PER_PAGE = `${BRANDFOLDER_BASE}/limits/documents_per_page`;
const BRANDFOLDER_SEARCH = `${BRANDFOLDER_BASE}/search`;
const BRANDFOLDER_VALIDATIONS = `${BRANDFOLDER_BASE}/validations`;
const BRANDFOLDER_ORDER_ASSETS = `${BRANDFOLDER_BASE}/order_assets`;

//
// Commerical Lines Endpoints
//
const GET_CL_NEW_QUOTE_NEW_BUSINESS_PRODUCT_LINES_URL = `${API_BASE_URL}/quote-new-business/product-lines${GET_COMMERCIAL_LINES}`;
const GET_CL_POLICY_INQUIRY = `${API_BASE_URL}/policy-inquiry/commercial-lines`;
const GET_CL_REDIRECT = `${API_BASE_URL}/redirect-url/commercial-lines`;
const GET_CL_RENEWALS_BASE = `${API_BASE_URL}/cls/renewals`;
const GET_CL_RENEWALS_DOCUMENTS = `${GET_CL_RENEWALS_BASE}/document-list`;
const GET_CL_RENEWALS_LOCATION_THRESHOLD = `${GET_CL_RENEWALS_BASE}/get-locations-threshold`;
const GET_CL_WIP_PRODUCT_LINES_URL = `${API_BASE_URL}/work-in-progress/product-lines${GET_COMMERCIAL_LINES}`;
const GET_CL_WIP_BASE_URL = `${API_BASE_URL}/work-in-progress/query?lineOfBusiness=commercial&vendorSystem=false`;
const GET_TRANSACTIONS_URL = `${API_BASE_URL}/transactions`;

//
// Clients List
//
const GET_CLIENTS = `${API_BASE_URL}/client-list`;
const GET_CLIENTS_ACCOUNTS = `${GET_CLIENTS}/clients`;
const GET_CLIENTS_LOBS = `${GET_CLIENTS}/lobs`;
const GET_CLIENTS_PRODUCTS = `${GET_CLIENTS}/products`;

//
// File and PDF Download Endpoints
//
const GET_DOWNLOAD_MEDIA = `${API_BASE_URL}/documents`;

//
// Personal Lines Endpoints
//
const GET_PL_DOCUMENTS_GET_DOCUMENTS = `${API_BASE_URL}/documents/get-documents`;
const GET_PL_NEW_QUOTE_NEW_BUSINESS_PRODUCT_LINES_URL = `${API_BASE_URL}/quote-new-business/product-lines${GET_PERSONAL_LINES}`;
const GET_PL_POLICIES_ON_NAME = `${API_BASE_URL}/policy-inquiry/insured-last-name/personal-lines`;
const GET_PL_POLICY_FORMS_URL = `${API_BASE_URL}/quote-new-business/policy-forms/`;
const GET_PL_WIP_BASE_URL = `${API_BASE_URL}/work-in-progress/query?lineOfBusiness=personal&vendorSystem=false`;
const GET_PL_DOCUMENTS_MA_AUTO_DOCS = `${API_BASE_URL}/documents/ma-auto-docs`;
const GET_PL_DOCUMENTS_MA_AUTO_PDF = `${API_BASE_URL}/documents/ma-auto-pdf`;

//
// Policy Inquiry Endpoints
//
const GET_INQUIRE_MSE_POLICY = `${API_BASE_URL}/policy-inquiry/main-street-express`;
const GET_INQUIRE_MSS_POLICY = `${API_BASE_URL}/policy-inquiry/main-street-station`;

//
// Quote New Business Endpoints
//
const GET_QUOTE_NEW_BUSINESS_STATUS = `${API_BASE_URL}/quote-new-business/status`;
const GET_RISK_STATES_URL = `${API_BASE_URL}/quote-new-business/risk-states`;

//
// Session Endpoints
//
const SESSION_URL = `${API_BASE_URL}/session`;

//
// Report Endpoints
//
const GET_REPORTS_URL = `${API_BASE_URL}/reports`;
const GET_AGENCY_SUGGESTIONS = `${GET_REPORTS_URL}/agencies/agency-suggestions`;
const GET_PERM_AGENCIES = `${GET_REPORTS_URL}/agencies/perm-agencies`;
const GET_ABS_URL = `${GET_REPORTS_URL}/agency-billed`;
const GET_ABS_REPORT_AGENTS = `${GET_ABS_URL}/agents`;
const GET_ABS_REPORT_DATES = `${GET_ABS_URL}/dates`;
const GET_APNE_URL = `${GET_REPORTS_URL}/apne`;
const GET_BMR_URL = `${GET_REPORTS_URL}/book-management`;
const GET_CC_URL = `${GET_REPORTS_URL}/chargeback`;
const GET_CC_REPORT_PERIODS = `${GET_CC_URL}/periods`;
const GET_DBC_URL = `${GET_REPORTS_URL}/direct-bill-commission`;
const GET_LOSSES_BY_AGENT_URL = `${GET_REPORTS_URL}/losses-by-agent`;
const GET_PLR_URL = `${GET_REPORTS_URL}/policy-loss-run`;
const GET_TAR_FORMS_URL = `${GET_REPORTS_URL}/tran-activity`;
const GET_TAR_URL = `${GET_REPORTS_URL}/transaction-activity`;

//
// Bill Payment Endpoints
//
const GET_BILL_PAYMENT_REPORT = `${API_BASE_URL}/reports/bill-payment`;
const GET_BILL_PAYMENT_REPORT_AGENT_CODES = `${GET_BILL_PAYMENT_REPORT}/agent-selections`;
const GET_BILL_PAYMENT_REPORT_AMOUNT_SELECTIONS = `${GET_BILL_PAYMENT_REPORT}/amount-selections`;
const GET_BILL_PAYMENT_REPORT_CONTENTS = `${GET_BILL_PAYMENT_REPORT}/contents`;
const GET_BILL_PAYMENT_REPORT_DATE_SELECTIONS = `${GET_BILL_PAYMENT_REPORT}/date-selections`;
const GET_BILL_PAYMENT_REPORT_EFT = `${GET_BILL_PAYMENT_REPORT}/EFT-selections`;
const GET_BILL_PAYMENT_REPORT_GENERATE_REPORT = `${GET_BILL_PAYMENT_REPORT}/generate-report`;
const GET_BILL_PAYMENT_REPORT_NSF_PAYMENTS = `${GET_BILL_PAYMENT_REPORT}/nsf-payments`;
const GET_BILL_PAYMENT_REPORT_PAYMENT_ACCOUNTS = `${GET_BILL_PAYMENT_REPORT}/payment-accounts`;
const GET_BILL_PAYMENT_REPORT_TRANSACTION_TYPES = `${GET_BILL_PAYMENT_REPORT}/trans-selections`;
const GET_BILL_PAYMENT_REPORT_USER_SELECTIONS = `${GET_BILL_PAYMENT_REPORT}/user-selections`;
const POST_BILL_PAYMENT_REPORT_POST_PAYMENTS = `${GET_BILL_PAYMENT_REPORT}/post-payments`;
const POST_BILL_PAYMENT_REPORT_REVERSE_PAYMENTS = `${GET_BILL_PAYMENT_REPORT}/reverse-payments`;
const GET_BILL_PAYMENT_REPORT_PAYMENT_BALANCE = `${GET_BILL_PAYMENT_REPORT}`;
const GET_BILL_PAYMENT_REPORT_PAYMENT_BALANCE_DETAILS = `${GET_BILL_PAYMENT_REPORT_PAYMENT_BALANCE}/payment-balance-details`;
const GET_BILL_PAYMENT_REPORT_PAYMENT_BALANCE_TOTALS = `${GET_BILL_PAYMENT_REPORT_PAYMENT_BALANCE}/payment-balance-totals`;

//
// Claims Inquiry Endpoints
//
const BASE_CLAIMS_URL = `${API_BASE_URL}/claims-inquiry`;
const POST_CLAIMS_AUTOCOMPLETE = `${BASE_CLAIMS_URL}/auto-completion`;
const GET_CLAIMS_DETAILS = `${BASE_CLAIMS_URL}/details`;
const POST_CLAIMS_SEARCH = `${BASE_CLAIMS_URL}/search`;

//
// Legal Agreement Endpoints
//
const LEGAL_AGREEMENT_ACCEPT = `${API_BASE_URL}/user/accept-legal`;
const LEGAL_AGREEMENT_PDF_DOWNLOAD = '/common-docs/LegalAgreement.pdf';

//
// User Admin Endpoints
//
const USER_ADMIN_ADD_GROUP = `${API_BASE_URL}/useradm/add-group`;
const USER_ADMIN_ALL_GROUPS = `${API_BASE_URL}/useradm/all-groups`;
const USER_ADMIN_CAN_EDIT = `${API_BASE_URL}/useradm/can-edit`;
const USER_ADMIN_DELETE_GROUP = `${API_BASE_URL}/useradm/delete-group`;
const USER_ADMIN_GET_PERMISSION = `${API_BASE_URL}/useradm/permissions/get-permission`;
const USER_ADMIN_GET_USER_INFO = `${API_BASE_URL}/useradm/user-info`;
const USER_ADMIN_GROUP_MEMBERS = `${API_BASE_URL}/useradm/group-members`;
const USER_ADMIN_GROUP_MEMBERS_DETAILS = `${API_BASE_URL}/useradm/group-members-details`;
const USER_ADMIN_SEARCH = `${API_BASE_URL}/useradm/search`;
const USER_ADMIN_PERMITTED_GROUPS = `${API_BASE_URL}/user/permitted-groups`;
const USER_ADMIN_USER_GROUPS = `${API_BASE_URL}/useradm/user-groups`;
const USER_ADMIN_UPDATE_USER_GROUP = `${API_BASE_URL}/useradm/update-user-group`;
const USER_ADMIN_HISTORY = `${API_BASE_URL}/useradm/change-history/for-user`;
const USER_ADMIN_CHALLENGE_QUESTIONS = `${API_BASE_URL}/useradm/challenge-questions`;
const USER_ADMIN_SET_NEW_PASSWORD = `${API_BASE_URL}/useradm/set-new-password`;
const USER_ADMIN_UPDATE_USER = `${API_BASE_URL}/useradm/update-user`;
const USER_ADMIN_CREATE_USER = `${API_BASE_URL}/useradm/create-user`;
const USER_ADMIN_PERMITTED_AGENCY_CODES = `${API_BASE_URL}/user/permitted-agency-codes`;

//
// Redirect constants
//
const REDIRECT_TO_NEW_WINDOW = 0;
const REDIRECT_TO_NEW_TAB = 1;
const REDIRECT_AS_REPLACEMENT = 2;

// Billing payment management history constants
const PAYMENT_MANAGEMENT_HISTORY = `${API_BASE_URL}/payment/management/history`;
const PAYMENT_MANAGEMENT_HISTORY_SELECTIONS = `${API_BASE_URL}/payment/management/history-selections`;
const PAYMENT_MANAGEMENT_HISTORY_BY_DETAIL_ID = `${API_BASE_URL}/payment/management/history/detailById`;

// Billing payment management manage current payment
const PAYMENT_MANAGEMENT_GET_CURRENT_PAYMENTS = `${API_BASE_URL}/payment/management/currentPayments`;
const PAYMENT_MANAGEMENT_UPDATE_CURRENT_PAYMENT = `${API_BASE_URL}/payment/management/updateCurrentPayment`;
const PAYMENT_MANAGEMENT_DELETE_CURRENT_PAYMENT = `${API_BASE_URL}/payment/management/deleteCurrentPayment`;

export {
  ACCOUNT_NUMBER_URL,
  ADD_DOC_NEWS,
  ADD_TEXT_NEWS,
  ADMIN_CHANGE_HISTORY_SEARCH,
  ADMIN_MANAGE_RESOURCES,
  ADMIN_REGIONAL_CONTACTS,
  AGENCY_ADMIN_ADD,
  AGENCY_ADMIN_GET_AVAILABLE_AGENCIES,
  AGENCY_ADMIN_GET_CURRENT_AGENCIES,
  AGENCY_ADMIN_GET_POTENTIAL_AGENCIES,
  AGENCY_ADMIN_GET_SELECTED_AGENCIES,
  AGENCY_ADMIN_PUT_ADD_REMOVE,
  AGENCY_ADMIN_REMOVE,
  AGENCY_ADMIN_SEARCH,
  ALL_NEWS_ARTICLES,
  ALL_STATES_FOR_NEWS,
  API_BASE_URL,
  BRANDFOLDER_DOCS_PER_PAGE,
  BRANDFOLDER_ORDER_ASSETS,
  BRANDFOLDER_SEARCH,
  BRANDFOLDER_VALIDATIONS,
  DELETE_ARTICLE,
  GET_ABS_REPORT_AGENTS,
  GET_ABS_REPORT_DATES,
  GET_AGENCY_HAS_MSA_ACCESS,
  GET_AGENCY_SUGGESTIONS,
  GET_ALLOWED_AGENCY_CODES,
  GET_ALL_AGENTS,
  GET_ANNOUNCEMENTS_ITEM,
  GET_ANNOUNCEMENTS_ITEMS,
  GET_ANNOUNCEMENTS_MONTHS,
  GET_ANNOUNCEMENTS_PRODUCTS,
  GET_ANNOUNCEMENTS_STATES,
  GET_ANNOUNCEMENTS_YEARS,
  GET_APNE_URL,
  GET_APPETITE,
  GET_BILLING_DETAILS,
  GET_BILLING_EXACT_SEARCH,
  GET_BILLING_PAYMENT_ACCOUNTS,
  GET_BILLING_PAYMENT_ADJUSTABLE_PAYMENTS,
  GET_BILLING_PAYMENT_NSF_PAYMENTS,
  GET_BILLING_SEARCH,
  GET_BILLING_SEARCH_PAGE_COUNT,
  GET_BILLING_SEARCH_V2,
  GET_BILL_PAYMENT_REPORT_AGENT_CODES,
  GET_BILL_PAYMENT_REPORT_AMOUNT_SELECTIONS,
  GET_BILL_PAYMENT_REPORT_CONTENTS,
  GET_BILL_PAYMENT_REPORT_DATE_SELECTIONS,
  GET_BILL_PAYMENT_REPORT_EFT,
  GET_BILL_PAYMENT_REPORT_GENERATE_REPORT,
  GET_BILL_PAYMENT_REPORT_NSF_PAYMENTS,
  GET_BILL_PAYMENT_REPORT_PAYMENT_ACCOUNTS,
  GET_BILL_PAYMENT_REPORT_PAYMENT_BALANCE_DETAILS,
  GET_BILL_PAYMENT_REPORT_PAYMENT_BALANCE_TOTALS,
  GET_BILL_PAYMENT_REPORT_TRANSACTION_TYPES,
  GET_BILL_PAYMENT_REPORT_USER_SELECTIONS,
  GET_BMR_URL,
  GET_CC_REPORT_PERIODS,
  GET_CLAIMS_DETAILS,
  GET_CLIENTS,
  GET_CLIENTS_ACCOUNTS,
  GET_CLIENTS_LOBS,
  GET_CLIENTS_PRODUCTS,
  GET_CL_NEW_QUOTE_NEW_BUSINESS_PRODUCT_LINES_URL,
  GET_CL_POLICY_INQUIRY,
  GET_CL_REDIRECT,
  GET_CL_RENEWALS_DOCUMENTS,
  GET_CL_RENEWALS_LOCATION_THRESHOLD,
  GET_CL_WIP_BASE_URL,
  GET_CL_WIP_PRODUCT_LINES_URL,
  GET_COMMERCIAL_LINES,
  GET_CONFIGURATION_OKTA,
  GET_DASHBOARD_OPEN_CLAIMS,
  GET_DASHBOARD_OVERDUE_PAYMENTS,
  GET_DASHBOARD_RENEWALS,
  GET_DASHBOARD_UNSOLD_QUOTES,
  GET_DASHBOARD_UNSOLD_QUOTES_STATUS,
  GET_DBC_URL,
  GET_DOWNLOAD_MEDIA,
  GET_INQUIRE_MSE_POLICY,
  GET_INQUIRE_MSS_POLICY,
  GET_LOSSES_BY_AGENT_URL,
  GET_NEWS,
  GET_NEWS_DASHBOARD_ANNOUNCEMENTS,
  GET_OKTA_LOGIN,
  GET_PAYMENTS_DUE_URL,
  GET_PERM_AGENCIES,
  GET_PERSONAL_LINES,
  GET_PLR_URL,
  GET_PL_AUTO_COMPLETION_AGENCY,
  GET_PL_AUTO_COMPLETION_INSURED,
  GET_PL_AUTO_COMPLETION_POLICY_ONE_SHIELD,
  GET_PL_DOCUMENTS_GET_DOCUMENTS,
  GET_PL_DOCUMENTS_MA_AUTO_DOCS,
  GET_PL_DOCUMENTS_MA_AUTO_PDF,
  GET_PL_NEW_QUOTE_NEW_BUSINESS_PRODUCT_LINES_URL,
  GET_PL_POLICIES_ON_NAME,
  GET_PL_POLICY_FORMS_URL,
  GET_PL_WIP_BASE_URL,
  GET_POLICY_NUMBER_SEARCH,
  GET_PROXY_URL,
  GET_QUOTE_NEW_BUSINESS_STATUS,
  GET_REDIRECT_URL,
  GET_REPORTS_URL,
  GET_RISK_STATES_URL,
  GET_TAR_FORMS_URL,
  GET_TAR_URL,
  GET_TRANSACTIONS_URL,
  GET_UI_DEFINITION,
  INSURED_LAST_NAME_COUNT_URL,
  INSURED_LAST_NAME_URL,
  LEGAL_AGREEMENT_ACCEPT,
  LEGAL_AGREEMENT_PDF_DOWNLOAD,
  NEWS_ARTICLES,
  NEWS_ARTICLE_BY_ID,
  PAYMENT_MANAGEMENT_DELETE_CURRENT_PAYMENT,
  PAYMENT_MANAGEMENT_GET_CURRENT_PAYMENTS,
  PAYMENT_MANAGEMENT_HISTORY,
  PAYMENT_MANAGEMENT_HISTORY_BY_DETAIL_ID,
  PAYMENT_MANAGEMENT_HISTORY_SELECTIONS,
  PAYMENT_MANAGEMENT_UPDATE_CURRENT_PAYMENT,
  POLICY_NUMBER_URL,
  POST_BILLING_PAYMENT,
  POST_BILLING_PAYMENT_ADJUST_PAYMENT,
  POST_BILLING_PAYMENT_POST_PAYMENTS,
  POST_BILLING_PAYMENT_REVERSE_PAYMENT,
  POST_BILLING_SEARCH_SUGGESTIONS,
  POST_BILL_PAYMENT_REPORT_POST_PAYMENTS,
  POST_BILL_PAYMENT_REPORT_REVERSE_PAYMENTS,
  POST_CLAIMS_AUTOCOMPLETE,
  POST_CLAIMS_SEARCH,
  REDIRECT_AS_REPLACEMENT,
  REDIRECT_TO_NEW_TAB,
  REDIRECT_TO_NEW_WINDOW,
  SESSION_URL,
  UPDATE_ARTICLE,
  USER_ADMIN_ADD_GROUP,
  USER_ADMIN_ALL_GROUPS,
  USER_ADMIN_CAN_EDIT,
  USER_ADMIN_CHALLENGE_QUESTIONS,
  USER_ADMIN_CREATE_USER,
  USER_ADMIN_DELETE_GROUP,
  USER_ADMIN_GET_PERMISSION,
  USER_ADMIN_GET_USER_INFO,
  USER_ADMIN_GROUP_MEMBERS,
  USER_ADMIN_GROUP_MEMBERS_DETAILS,
  USER_ADMIN_HISTORY,
  USER_ADMIN_PERMITTED_AGENCY_CODES,
  USER_ADMIN_PERMITTED_GROUPS,
  USER_ADMIN_SEARCH,
  USER_ADMIN_SET_NEW_PASSWORD,
  USER_ADMIN_UPDATE_USER,
  USER_ADMIN_UPDATE_USER_GROUP,
  USER_ADMIN_USER_GROUPS
};

