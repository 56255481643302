import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';

import { Dialog, Divider, Paper, Box, Typography, Button } from '../AORedesign';
import {
  handleAgreeOnClick,
  handleDeclineOnClick,
  handleDownloadLegalAgreementOnClick,
} from './LegalAgreement.api';
import { AOLinkButton } from '../AOLinkButton';

import { AOAlert } from '../AOAlert';

const LegalAgreement = ({ hideLegalAgreementModal }) => {
  const { oktaAuth } = useOktaAuth();

  const [downloadingPdf, setDownloadingPdf] = useState(false);
  const [agreeButtonLoading, setAgreeButtonLoading] = useState(false);
  const [error, setError] = useState('');

  return (
    <Dialog
      open
      aria-labelledby="legal-agreement-modal-title"
      aria-describedby="legal-agreement-modal-description">
      <Paper>
        <Typography component="div" variant="h5" id="legal-agreement-header">
          <Box textAlign="center" mt={2} mb={3}>
            Legal Agreement
          </Box>
        </Typography>

        <Divider />

        <Typography component="div" color="textSecondary">
          <Box my={2} fontWeight="bold">
            FOR ALL AGENCY USERS:
          </Box>
        </Typography>

        <Typography
          component="div"
          color="textSecondary"
          id="legal-agreement-underwriting-WEB SITE ACCESS SECURITY AGREEMENT">
          <Box textAlign="center" mb={1} fontWeight="bold">
            AGREEMENT TO PROTECT UNDERWRITING INFORMATION
          </Box>

          <Box mb={1}>
            {`By Selecting "I Agree", underwriting report functionality within The Main Street America
            Group is turned on. Credit will automatically be requested/returned/utilized behind the
            scenes. You will also have the opportunity to request Solutions at Quote as well as
            select applicant information ("Applicant Information") from Current Carrier, MVR
            and/or C.L.U.E. reports. When ordering reports for underwriting purposes, you must have
            a completed application and/or the verbal request of the subject consumer.`}
          </Box>

          <Box mb={1}>
            <Box display="inline" fontWeight="bold" mr={0.5}>
              THE LAW
            </Box>
            <Box display="inline">
              {`- Your use and disclosure of Applicant Information is governed by (1) Sections 502(e)(1(2), (2), (3)(B) and/or (6) of the Gramm-Leach-Bliley Act, and its state analogues, (2) the Federal Fair Credit Reporting Act, 15 U.S.C. §§ 1681 et seq., and its state analogues, and (3) other state and federal laws.`}
            </Box>
          </Box>

          <Box mb={1}>
            The Federal Fair Credit Reporting Act imposes criminal penalties - including a fine, up
            to two years in prison, or both - against anyone who knowingly and willfully obtains
            such information from a consumer reporting agency under false pretenses, and or other
            penalties for anyone who obtains such consumer information without a permissible
            purpose.
          </Box>

          <Box mb={1}>
            <Box display="inline" fontWeight="bold" mr={0.5}>
              CONFIDENTIALITY
            </Box>
            <Box display="inline">
              - For C.L.U.E. and Current Carrier reports, no further disclosure of the Applicant
              Information shall be made to any person, except the person reported on in the case of
              adverse action. You agree you shall be liable for any use and/or misuse of any
              C.L.U.E., MVR, or Current Carrier reports requested through the Priority System.
              Further, you agree to protect all Applicant Information as confidential, and to use
              the Applicant Information only for the purpose of underwriting the insurance risk for
              which the information has been acquired.
            </Box>
          </Box>

          <Box mb={1}>
            <Box display="inline" fontWeight="bold" mr={0.5}>
              HOLD HARMLESS
            </Box>
            <Box display="inline">
              {`- You agree to hold ChoicePoint or its affiliated companies (together referred to herein as "ChoicePoint") and NGM Insurance Company, its parent, subsidiary or affiliated entities in the same mutual insurance holding company structure (together referred to herein as the "MSA Group") harmless for third party liability arising out of your violation of the above agreements. Such hold harmless will include all reasonable and customary costs and all reasonable and customary expenses paid by the MSA Group and/or ChoicePoint in connection with such claims.`}
            </Box>
          </Box>

          <Box mb={1} fontWeight="bold">
            ADDITIONAL FOR AGENCY OWNERS AND PRINCIPALS:
          </Box>

          <Box textAlign="center" mb={1} fontWeight="bold" id="legal-agreement-security-agreement">
            AO/MSA Group WEB SITE ACCESS SECURITY AGREEMENT
          </Box>

          <Box mb={1}>
            {`The AO/MSA Group Web site Access Security Agreement (hereinafter "Agreement") is the means by which you (and any other owner/principal of your agency) are granted control of and accept responsibility for the administration of your agency's access, including access by your agency's employees, to all of the agency and policyholder/claimant information available via the Agents Only portion of the MSA Group Web site (herein "AO/MSA Group Web site"). This Agreement also sets forth some expectations and guidelines for acceptable and responsible security access to the AO/MSA Group Web site. NOTE: The Conditions of Web site Use of the MSA Group Web site also apply in conjunction with any terms and conditions contained herein.`}
          </Box>

          <Box mb={1}>
            {`You should read the following terms and conditions carefully. By accepting this Agreement and using the AO/MSA Group Web site you are affirmatively agreeing to be bound by the Agreement, just as if you had signed it. Moreover, you agree that MSA Group may terminate your agency's access with or without notice if you fail to comply with the Agreement.`}
          </Box>

          <Box mb={1}>
            {`IF, AFTER REVIEWING ALL OF THE FOLLOWING TERMS, YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT YOU SHOULD IMMEDIATELY END YOUR USE OF THE AO/MSA Group WEB SITE AND SOFTWARE AND NOTIFY MSA Group SO WE MAY INITIATE A CLOSURE OF YOUR AGENCY'S WEB SITE ACCESS.`}
          </Box>

          <Box textAlign="center" mb={1} fontWeight="bold" id="legal-agreement-introduction">
            INTRODUCTION
          </Box>
          <Box mb={1}>
            {`At MSA Group, we are serious about information security and seek your cooperation and support in this effort. We will offer you the information and training that you will need in order to establish and administer access to the AO/MSA Group Web site, so you, and your agency's employees, can access the agency and policyholder/claimant information available via the AO/MSA Group Web site.`}
          </Box>

          <Box textAlign="center" mb={1} fontWeight="bold" id="legal-agreement-responsibility">
            RESPONSIBILITY
          </Box>
          <Box mb={1}>
            Information protection is vital to you and to us. Inadequate protection or misuse of
            agency, policyholder/claimant or MSA Group company information could give competitors an
            unfair advantage, lessen the quality of our services, undermine or damage customer
            relationships and potentially lead to legal conflicts.
          </Box>

          <Box mb={1}>
            <Box
              display="inline"
              fontWeight="bold"
              mr={0.5}
              style={{ textDecoration: 'underline' }}>
              {`You are responsible for the security and use of the agency and policyholder/claimant
            information available to you through the MSA Group Web site. Your personal attention to
            the security access of such information is critical to protecting the assets and
            integrity of your agency and policyholder/claimant information and MSA Group's network.`}
            </Box>
            <Box display="inline">
              {`This responsibility applies regardless of a PC's location (i.e., in the office, at home or on the road).`}
            </Box>
          </Box>

          <Box mb={1}>
            <Box display="inline">
              MSA Group will employ, to the best of its ability, information security systems,
              processes and procedures that will safeguard access to information pertaining to your
              agency, your customers, prospective clients and claimants. To protect such information
              you should require that all agency employees also safeguard access to this
              information.
            </Box>
            <Box
              display="inline"
              fontWeight="bold"
              ml={0.5}
              style={{ textDecoration: 'underline' }}>
              First of all, you should ensure that all passwords are kept confidential and are not
              shared among employees.
            </Box>
          </Box>

          <Box mb={1}>
            <Box
              display="inline"
              fontWeight="bold"
              mr={0.5}
              style={{ textDecoration: 'underline' }}>
              {`If a terminated employee's access is not revoked, he/she will continue to be able to view policyholder, billing and claim information and possibly even agency information from any personal computer connected to the Internet,`}
            </Box>
            <Box display="inline">
              {`anywhere - including those located at the former employee's home or from a competitor's office. It is your responsibility to ensure that access to the AO/MSA Group Web site by former agency employees is immediately disabled. MSA Group assumes no responsibility in this regard.`}
            </Box>
          </Box>

          <Box textAlign="center" fontWeight="bold" mb={1} id="legal-agreement-acceptance">
            ACCEPTANCE - AGENCY SECURITY ACCESS RESPONSIBILITY
          </Box>
          <Box mb={1}>
            I understand that I am being given the means and the responsibility to administer
            security access to all of the agency and policyholder/claimant information available to
            my agency, including its employees, via the AO/MSA Group Web site. Training is being
            made available to me so that I can fully understand and operate the administrative
            functionality being given to me.
          </Box>

          <Box mb={1}>
            I also understand that if security access is not properly administered by me, or by
            those I designate within my agency, it may result in the unintended disclosure of both
            agency and policyholder/claimant information to third parties.
          </Box>

          <Box mb={1}>
            Understanding the above, I hereby fully accept responsibility for administering the
            security access to all of the agency and policyholder/claimant information available to
            my agency, including its employees, via the AO/MSA Group Web site.
          </Box>

          <Box mb={1} fontStyle="italic">
            Web site Security 8/2007
          </Box>
        </Typography>

        <AOLinkButton
          id="download-legal-agreement-link"
          onClick={() => handleDownloadLegalAgreementOnClick(setDownloadingPdf, setError, setError)}
          loading={downloadingPdf}>
          Click Here to Download this Legal Agreement in Adobe .PDF format
        </AOLinkButton>
        <Box mt={2} mb={3}>
          <Divider />
        </Box>
        {error && <AOAlert severity="error">{error}</AOAlert>}
        <Box display="flex" justifyContent="flex-end">
          <Button
            onClick={() => handleDeclineOnClick(setError, oktaAuth)}
            color="primary"
            variant="outlined">
            I Decline
          </Button>
          <Box mr={2} />
          <Button
            loading={agreeButtonLoading}
            onClick={() =>
              handleAgreeOnClick(setAgreeButtonLoading, setError, hideLegalAgreementModal)
            }
            color="primary"
            variant="contained">
            I Agree
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

LegalAgreement.propTypes = {
  hideLegalAgreementModal: PropTypes.func,
};

export default LegalAgreement;
